<template>
  <v-menu
    v-model="menuVisible"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="290px"
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        :label="label"
        append-icon="mdi-calendar"
        readonly
        :value="displayValue"
        v-on="on"
        :rules="rules"
        :hide-details="hideDetails"
      ></v-text-field>
    </template>
    <v-date-picker
      :locale="locale"
      :value="currentValue"
      no-title
      @input="dateSelected"
      :min="min"
      :max="max"
    ></v-date-picker>
  </v-menu>
</template>

<script>
import { memory } from "ngt-frontend-core";

export default {
  name: "GsDatePicker",
  props: {
    value: {
      type: String,
      default: "2020-02-02"
    },
    label: {
      type: String,
      default: ""
    },
    min: {
      type: String,
      default: null
    },
    max: {
      type: String,
      default: null
    },
    rules: {
      type: Array,
      default() {
        return [];
      }
    },
    hideDetails: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    locale: "en-in",
    menuVisible: false,
    currentValue: null
  }),
  computed: {
    displayValue() {
      return this.currentValue;
      // format/do something with date
    }
  },
  created() {
    memory.getItem("locale", locale => {
      //   console.log(locale)
      this.locale = locale;
    });
    this.currentValue = this.value;
  },
  methods: {
    dateSelected(value) {
      this.currentValue = value;
      this.menuVisible = false;
      this.$emit("input", this.currentValue);
    }
  }
};
</script>

<style scoped></style>

<template>
  <v-container class="container" fluid>
    <div class="d-flex flex-row align-center">
      <!-- filter panel -->
      <GsDataFilter
        style="width: 100%"
        ref="dataFilter"
        :appSetting="appSettingKey"
        :filters="filters"
        v-on:changed="onFilterChanged"
      />
    </div>
    <div v-if="customerUri">
      <v-card flat class="mt-5 mb-3">
        <v-btn
          small
          class="elevation-4"
          color="primary"
          v-on:click="add_click()"
        >
          {{ $t("add_button") }}
        </v-btn>
      </v-card>
      <GsDataTable
        ref="table"
        api=""
        endpoint="/licenses"
        :appSetting="appSettingKey"
        :headers="headers"
        :default-sort-by="['name']"
        :default-sort-desc="[false]"
        :beforeCallApi="beforeCallApi"
        :afterCallApi="afterCallApi"
        :single-expand="singleExpand"
        :expanded.sync="expanded"
        item-key="licenseId"
        show-expand
        @click:row="rowClicked"
        @after-refresh="afterRefresh"
      >
        <template v-slot:item.renewable="{ item }">
          <span v-if="item.renewable === 0">{{ $t("no") }}</span>
          <span v-if="item.renewable === 1">{{ $t("yes") }}</span>
        </template>

        <template v-slot:item.validFrom="{ item }">
          <span>{{ item.validFrom | getDisplayDate }}</span>
        </template>

        <template v-slot:item.validTo="{ item }">
          <span>{{ item.validTo | getDisplayDate }}</span>
        </template>

        <template v-slot:item.status="{ item }">
          <span v-if="item.status === 'license.status.active'">{{
            $t("licenseStatusActive")
          }}</span>
          <span v-if="item.status === 'license.status.upgraded'">{{
            $t("licenseStatusUpgraded")
          }}</span>
          <span v-if="item.status === 'license.status.suspended'">{{
            $t("licenseStatusSuspended")
          }}</span>
        </template>

        <template v-slot:item.createdAt="{ item }">
          <span v-if="item.createdAt">{{
            item.createdAt | getDisplayDate
          }}</span>
          <br />
          <span v-if="item.createdBy && item.createdBy.name">
            ({{ item.createdBy.name | empty }})</span
          >
        </template>

        <template v-slot:item.updatedAt="{ item }">
          <span v-if="item.updatedAt">{{
            item.updatedAt | getDisplayDate
          }}</span>
          <br />
          <span v-if="item.updatedBy && item.updatedBy.name">
            ({{ item.updatedBy.name | empty }})</span
          >
        </template>

        <template v-slot:item.actions="{ item }">
          <GsActionsMenu
            :actions="actions"
            :onActivate="actionId => action_activate(actionId, item)"
          />
        </template>
      </GsDataTable>
    </div>

    <!-- unified modal for actions -->
    <v-dialog
      transition="dialog-bottom-transition"
      max-width="600"
      persistent
      v-model="openDialog"
    >
      <v-card>
        <v-toolbar color="primary" dark
          >{{ getDialogTitle(modalContent) }}
        </v-toolbar>
        <v-card-text class="mt-4">
          <!-- action specifics -->
          <template
            v-if="modalContent.action === 'transaction-log.action.renew'"
          >
            <div>{{ $t("actionRenewLead") }}</div>
          </template>
          <template
            v-if="modalContent.action === 'transaction-log.action.set-value'"
          >
            <div>{{ $t("actionSetValueLead") }}</div>
            <div
              class="mt-4"
              :class="!modalContent.inputValues.name ? 'red lighten-5' : null"
            >
              <v-text-field
                :label="$t('name')"
                v-model="modalContent.inputValues.name"
                type="text"
                maxlength="256"
                hide-details
              />
            </div>
            <div
              class="mt-3"
              :class="
                !modalContent.inputValues.description ? 'red lighten-5' : null
              "
            >
              <v-text-field
                :label="$t('description')"
                v-model="modalContent.inputValues.description"
                type="text"
                maxlength="256"
                hide-details
              />
            </div>
            <div
              class="mt-3"
              :class="!modalContent.inputValues.status ? 'red lighten-5' : null"
            >
              <v-select
                :label="$t('status')"
                v-model="modalContent.inputValues.status"
                :items="[
                  'license.status.active',
                  'license.status.upgraded',
                  'license.status.suspended'
                ]"
                hide-details
              />
            </div>
            <div
              class="mt-3"
              :class="
                !modalContent.inputValues.validTo ? 'red lighten-5' : null
              "
            >
              <GsDatePicker
                :label="$t('validTo')"
                v-model="modalContent.inputValues.validTo"
                hide-details
              />
            </div>
          </template>
          <template
            v-if="modalContent.action === 'transaction-log.action.refresh'"
          >
            <div>{{ $t("actionRefreshLead") }}</div>
          </template>
          <template
            v-if="modalContent.action === 'transaction-log.action.upgrade'"
          >
            <div>{{ $t("actionUpgradeLead") }}</div>
            <div
              class="mt-4"
              :class="
                !modalContent.inputValues.licenseTemplateId
                  ? 'red lighten-5'
                  : null
              "
            >
              <GsInfiniteDropdown
                :config="licenseTemplateSelectionConfig"
                v-model="modalContent.inputValues.licenseTemplateId"
                class="mb-4"
              />
            </div>
            <div
              class="mt-5"
              :class="
                !modalContent.inputValues.validFrom ? 'red lighten-5' : null
              "
            >
              <GsDatePicker
                :label="$t('validFrom')"
                v-model="modalContent.inputValues.validFrom"
                hide-details
              />
            </div>
          </template>
          <template
            v-if="modalContent.action === 'transaction-log.action.delete'"
          >
            <div>{{ $t("actionDeleteLead") }}</div>
          </template>

          <v-text-field
            :label="`${$t('comment')}`"
            v-model="modalContent.comment"
            class="mt-4"
          />

          <!-- errors -->
          <div class="d-flex flex-row" v-if="error">
            <span class="subtitle-1 mb-4 red--text"
              >{{ $t("form_invalid_text") }}: {{ error }}</span
            >
          </div>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn text @click="closeTheDialog">{{ $t("close") }}</v-btn>
          <v-btn
            text
            color="teal lighten-2"
            @click="transactionAction(modalContent)"
            >{{ $t("save") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
const { callBffAPI } = require("ngt-frontend-core").apiOpsBff;
import { GsDataTable, GsActionsMenu, GsDataFilter } from "ngt-frontend-core";
import { GsInfiniteDropdown } from "ngt-frontend-core";
import GsDatePicker from "@/components/GsDatePicker";

export default {
  name: "Licenses",
  components: {
    GsDataTable,
    GsActionsMenu,
    GsDataFilter,
    GsInfiniteDropdown,
    GsDatePicker
  },
  data() {
    return {
      appSettingKey: "licenses",
      initialized: false,
      items: [],
      expanded: [],
      singleExpand: true,
      filters: [
        {
          field: "ownedBy",
          component: "GsInfiniteDropdownFilter",
          params: {
            options: [],
            clearable: false
          },
          config: {
            dataConnector: callBffAPI,
            dataFields: {
              uri: "/customers",
              sort: "name:asc",
              fields: "name,_uri",
              search: "name:ic:",
              dataMap: {
                title: "name",
                value: "_uri"
              }
            },
            dense: true,
            solo: true,
            flat: true,
            limit: 50,
            page: 0,
            value:
              this.$store.state?.appSettings?.licenses?.filter?.ownedBy
                ?.value || this.customerUri
          },
          width: "longest"
        }
      ],
      customerUri: null,
      actions: [
        "license_renew",
        "license_set_values",
        "license_refresh",
        "license_upgrade",
        "license_delete"
      ],
      loading: false,
      error: null,
      openDialog: null,
      modalContent: {}
    };
  },
  async mounted() {
    this.initialized = false;
    this.$nextTick(() => {
      this.initialized = true;
    });
    await this.update();
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t("actions"),
          value: "actions",
          width: 100,
          sortable: false
        },
        {
          text: this.$t("name"),
          align: "left",
          value: "name",
          width: 150,
          sortable: true
        },
        {
          text: this.$t("description"),
          align: "left",
          value: "description",
          width: 150,
          sortable: false
        },
        {
          text: this.$t("interval"),
          align: "left",
          value: "interval",
          width: 150,
          sortable: false
        },
        {
          text: this.$t("renewable"),
          align: "left",
          value: "renewable",
          width: 150,
          sortable: false
        },
        {
          text: this.$t("validFrom"),
          align: "left",
          value: "validFrom",
          width: 150,
          sortable: true
        },
        {
          text: this.$t("validTo"),
          align: "left",
          value: "validTo",
          width: 150,
          sortable: true
        },
        {
          text: this.$t("status"),
          align: "left",
          value: "status",
          width: 150,
          sortable: true
        },
        {
          text: this.$t("createdAt"),
          value: "createdAt",
          width: 150,
          sortable: true
        },
        {
          text: this.$t("updatedAt"),
          value: "updatedAt",
          width: 150,
          sortable: true
        },
        { text: "", value: "data-table-expand" }
      ];
    },
    licenseTemplateSelectionConfig() {
      return {
        dataConnector: callBffAPI,
        dataFields: {
          uri: "/license-templates",
          sort: "name:asc",
          fields: "name,licenseTemplateId",
          search: "name:ic:",
          dataMap: {
            title: "name",
            value: "licenseTemplateId"
          }
        },
        label: this.$t("licenseTemplate"),
        limit: 50,
        page: 0,
        value: null
      };
    }
  },
  methods: {
    async update() {
      const filter = this.$refs.dataFilter.getFilter();
      const customerUri = filter.substring("&filter=ownedBy:eq:".length);
      this.customerUri = customerUri;
    },
    async onFilterChanged() {
      await this.update();
      if (this.customerUri) {
        this.refreshTable();
      }
    },
    async refreshTable() {
      try {
        // console.log("refreshTable");
        await this.$refs.table.refreshTable();
      } catch (error) {
        this.errorSnackbar(error);
      }
    },
    async beforeCallApi(params) {
      params.url += this.$refs.dataFilter.getFilter();
      params.url += "&r8sFields=createdBy.name,updatedBy.name";
      return params;
    },
    async afterCallApi(params) {
      // await this.calculatePropertiesCustomers(params.items);
      return params;
    },
    afterRefresh({ items }) {
      const expandedId = this.loadExpanded();
      if (expandedId) {
        const expandItem = items.find(p => p.customerId == expandedId);
        if (expandItem) {
          this.expanded = [expandItem];
          this.item = expandItem;
        }
      }
    },
    rowClicked(item) {
      const earlyReturn = this.expanded.some(
        p => p.customerId === item.customerId
      );
      if (earlyReturn) {
        this.expanded = [];
        this.saveExpanded(null);
        return;
      }
      this.expand(item);
    },
    expand(item) {
      if (!this.$refs.table) return;
      if (item) {
        this.expanded = [item];
        this.saveExpanded(item.customerId);
        this.item = item;
      }
    },
    async add_click() {
      if (!this.customerUri) {
        return;
      }
      this.$router.push_safe(
        `/licenses/${encodeURIComponent(this.customerUri)}`
      );
    },
    async action_activate(actionId, item) {
      switch (actionId) {
        case "license_renew":
          this.license_renew(item);
          break;
        case "license_set_values":
          this.license_set_values(item);
          break;
        case "license_refresh":
          this.license_refresh(item);
          break;
        case "license_upgrade":
          this.license_upgrade(item);
          break;
        case "license_delete":
          this.license_delete(item);
          break;
      }
    },
    license_renew(item) {
      this.modalContent = {
        licenseId: item.licenseId,
        action: "transaction-log.action.renew",
        inputValues: {},
        comment: "",
        item
      };
      this.openDialog = true;
    },
    license_set_values(item) {
      this.modalContent = {
        licenseId: item.licenseId,
        action: "transaction-log.action.set-value",
        inputValues: {
          name: item.name,
          description: item.description,
          status: item.status,
          validTo: item.validTo
        },
        comment: "",
        item
      };
      this.openDialog = true;
    },
    license_refresh(item) {
      this.modalContent = {
        licenseId: item.licenseId,
        action: "transaction-log.action.refresh",
        inputValues: {},
        comment: "",
        item
      };
      this.openDialog = true;
    },
    license_upgrade(item) {
      this.modalContent = {
        licenseId: item.licenseId,
        action: "transaction-log.action.upgrade",
        inputValues: { licenseTemplateId: null, validFrom: null },
        comment: "",
        item
      };
      this.openDialog = true;
    },
    license_delete(item) {
      this.modalContent = {
        licenseId: item.licenseId,
        action: "transaction-log.action.delete",
        inputValues: {},
        comment: "",
        item
      };
      this.openDialog = true;
    },
    async transactionAction(data) {
      this.error = null;

      if (!data.licenseId) {
        return;
      }
      if (data.action === "transaction-log.action.upgrade") {
        if (
          !data.inputValues.licenseTemplateId ||
          !data.inputValues.validFrom
        ) {
          return;
        }
      }
      if (data.action === "transaction-log.action.set-value") {
        if (
          !data.inputValues.name ||
          !data.inputValues.description ||
          !data.inputValues.status ||
          !data.inputValues.validTo
        ) {
          return;
        }
      }

      delete data.item;
      if (!data.comment) {
        delete data.comment;
      }

      try {
        this.loading = true;
        const response = await callBffAPI({
          url: `${this.$store.state.idp_api_url}/licenses/${data.licenseId}/transaction-logs`,
          method: "POST",
          data
        });
        if (response.status == 204 || response.status == 200) {
          if (response.errorReason) {
            this.error = response.errorReason;
            return;
          }
          this.refreshTable();
          this.closeTheDialog();
        }
      } catch (err) {
        const data = err?.response?.data;
        if (data.statusCode == 400) {
          this.error = this.$t(data.validation.keys.join(", "));
        } else {
          this.errorSnackbar(err);
        }
      } finally {
        this.loading = false;
      }
    },
    closeTheDialog() {
      this.openDialog = null;
      this.modalContent = {};
      this.error = null;
    },
    getDialogTitle(modalContent) {
      switch (modalContent?.action) {
        case "transaction-log.action.renew":
          return `${this.$t("licenseRenew")}: ${modalContent?.name}`;
        case "transaction-log.action.set-value":
          return `${this.$t("licenseSetValues")}: ${modalContent?.name}`;
        case "transaction-log.action.refresh":
          return `${this.$t("licenseRefresh")}: ${modalContent?.name}`;
        case "transaction-log.action.upgrade":
          return `${this.$t("licenseUpgrade")}: ${modalContent?.name}`;
        case "transaction-log.action.delete":
          return `${this.$t("licenseDelete")}: ${modalContent?.name}`;
      }
    }
  }
};
</script>

<style lang="scss"></style>
